/**
 * jQuery
 */
let $ = require('jquery');

/**
 * Popper
 */
require('popper.js');

/**
 * Bootstrap
 */
require('bootstrap');

//prevent right click to protect images for download
window.addEventListener('contextmenu', function (e) {
    e.preventDefault();
}, false);
